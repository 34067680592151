/* src/styles/AboutUsPage.css */

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  padding: 20px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.about-content {
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Semi-transparent background for readability */
  padding: 40px; /* Increased padding to make the content box larger */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  max-width: 1000px; /* Increased max-width for a wider box */
  margin: 20px auto;
  animation: fadeIn 1s ease-in-out;
}

.about-content h1 {
  font-size: 2.8rem; /* Slightly larger font for the heading */
  margin-bottom: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* Style for hyperlinks */
a.link {
  color: inherit; /* Match hyperlink color to text */
  text-decoration: none;
  transition: color 0.3s ease;
}

a.link:hover {
  color: #d3d3d3; /* Dark grey hover color */
}

.image-gallery {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.about-image {
  width: 33%; /* Make images take up more space */
  max-width: 300px; /* Max-width to prevent the images from stretching too much */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.about-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Responsive design */
@media (max-width: 768px) {
  .about-image {
    width: 90%; /* Full width on mobile */
    margin-bottom: 15px;
  }

  .image-gallery {
    flex-direction: column;
    align-items: center;
  }
}
