/* Footer.css */

.footer {
  background-color: #333;
  color: #fff;
  padding: 5px 10px; /* Make the footer height smaller */
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  height: 40px; /* Fixed height for a thin footer */
}

.footer-links {
  display: flex;
  gap: 15px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-copyright {
  margin: 0;
  color: #ccc;
}
