.menu-container {
  position: relative;
  min-height: 100vh;
  padding: 20px;
  z-index: 1; /* Ensures that the content is above the background image */
}

.menu-section-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Allow sections to grow independently */
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

/* Styling each category section */
.menu-section {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 30%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
}

.meat-category {
  margin-bottom: 20px;
}

.meat-category h3 {
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.menu-item {
  background-color: #e9ecef;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  word-break: break-word; /* Ensures long text wraps within the item box */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .menu-section-container {
    flex-direction: column;
    align-items: center;
  }

  .menu-section {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
