.admin-panel-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f7f9fc;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.section-separator {
  border: none;
  height: 2px;
  background-color: black;
  margin: 20px 0;
}

.menu-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.menu-form label {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  text-align: right;
  padding-right: 10px;
}

.menu-form input, .menu-form select {
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #d1d5da;
  width: 100%;
}

button {
  grid-column: 1 / -1;
  padding: 12px 20px;
  background-color: #007BFF;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #0056b3;
}

.menu-title {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
}

/* Horizontal layout for menu sections */
.menu-items-list {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
}

/* Each menu section styling */
.menu-section {
  flex: 1;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 250px;
}

.menu-section h3 {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.menu-item {
  background-color: #e9ecef;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .menu-items-list {
    flex-direction: column;
  }
}
