.landing-container {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 20px;
}

.landing-image img {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Enhanced news-feed styling */
.news-feed {
  flex: 1;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #d1d5da;
  margin-left: 40px;
  max-height: 500px; /* Match image height */
  overflow-y: auto;
  width: 100%;
}
.landing-image {
  width: 100%;
  max-width: 800px;
  height: 500px; /* Fixed height to keep box consistent */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideshow-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  transition: opacity 0.5s ease-in-out;
}




.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}




/* Styled announcement items */
.announcement-item {
  background-color: #ffffff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #d1d5da;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.announcement-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.announcement-item img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.timestamp {
  font-size: 12px;
  color: gray;
  margin-top: 5px;
  text-align: center;
}

.landing-description {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  max-width: 600px;
  margin: 20px auto;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  color: #fff;
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
}

.admin-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.admin-button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .landing-content {
    flex-direction: column;
  }

  .landing-image,
  .news-feed {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .admin-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
