/* src/styles/NavBar.css */

.navbar {
  text-align: center;
  margin-top: 20px;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: inline-flex;
  gap: 50px; /* Increased spacing between items */
}

.nav-item {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  position: relative;
  color: #000; /* Default color */
  font-size: 24px; /* Increased font size */
}

/* Animation for the 'Home' link */
.home-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px; /* Thicker line */
  background: #000;
  position: absolute;
  left: 0;
  bottom: -8px; /* Adjusted position below text */
  transition: width 0.3s ease-in-out;
}

.home-link:hover::after {
  width: 100%; /* Expands from left to right */
}

/* Animation for the 'About Us' link on the Landing Page */
.landing-about-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: width 0.3s ease-in-out;
}

.landing-about-link:hover::after {
  width: 100%; /* Expands from left to right */
}

/* Animation for the 'About Us' link on All Other Pages */
.page-about-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
}

.page-about-link:hover::after {
  width: 100%; /* Expands from center outward */
}

/* Animation for the 'Menu' link */
.landing-menu-link::after,
.page-menu-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
}

.landing-menu-link:hover::after,
.page-menu-link:hover::after {
  width: 100%; /* Expands from center outward */
}

/* Animation for the 'Contact Us' link */
.landing-contact-link::after,
.page-contact-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  position: absolute;
  right: 0;
  bottom: -8px;
  transition: width 0.3s ease-in-out;
}

.landing-contact-link:hover::after,
.page-contact-link:hover::after {
  width: 100%; /* Expands from right to left */
}
