/* src/styles/Header.css */
.header {
  text-align: center;
  margin-bottom: 30px; /* Increased margin for more spacing */
}

.company-name {
  margin: 0;
  font-size: 64px; /* Increased font size */
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.name-link {
  text-decoration: none;
  color: #000;
}

.name-link:hover {
  color: #666666; /* Change color on hover */
}

.est {
  margin: 0;
  font-size: 18px; /* Slightly bigger EST text */
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000;
}
