/* src/styles/ContactUsPage.css */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"); /* Importing Roboto */

body {
  font-family: "Roboto", sans-serif; /* Apply Roboto font */
  margin: 0;
  padding: 0;
}

.contact-container {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding: 20px;
  position: relative;
}

.contact-info {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7); /* Shadow effect */
  max-width: 600px;
  margin: 20px auto;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7); /* Text shadow */
  color: #fff; /* White text */
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.contact-info p,
ul {
  font-size: 1.2rem;
  margin: 10px 0;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}

.social-links a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fff; /* White for consistency */
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ff5722; /* Accent color for hover */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
